@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import "./dashboard/dashboard";

@layer utilities {
	@variants responsive {
		.no-scrollbar::-webkit-scrollbar {
			display: none;
		}

		.no-scrollbar {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
	}
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

body {
	margin: 0;
	font-family: "Montserrat";
	background: white;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
	display: none;
}

input[type="date"] {
	min-width: 100% !important;
}

input::-webkit-date-and-time-value {
	text-align: left;
	padding-left: 7px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
	display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield; /* Firefox */
}

.select {
	box-shadow: 0px 4px 48px rgba(178, 187, 198, 0.35);
}

.modal-bg {
	background: rgba(52, 64, 84, 0.9);
	backdrop-filter: blur(1px);
}

.loader-container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	text-align: center;
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;

	.loader {
		border: 1.5px solid #f3f3f3;
		border-top: 1.5px solid #513cff;
		border-radius: 50%;
		width: 18px;
		height: 18px;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.drop-shadow {
	box-shadow: 0px 4px 14px 0px #2020231a;
}
