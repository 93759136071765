.white-icon {
  filter: brightness(0) invert(1);
}

.green-gradient {
  background: linear-gradient(173deg, #d0eccb -18.7%, #fff 60.41%);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.06);
}

.purple-gradient {
  background: linear-gradient(173deg, #dfd6fd -18.7%, #fff 60.41%);
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.06);
}

.react-datepicker {
  color: #202529;
  border: none !important;
  display: inline-block;
  position: relative;
  font-size: 10px !important;
  margin-left: 1px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: 'Montserrat', sans-serif !important;
}

.react-datepicker__header {
  text-align: center;
  background-color: transparent !important;
  border-bottom: none !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  padding-top: 0px !important;
  position: relative;
}

.react-datepicker__day {
  background-color: #f8f9fb !important;
  color: #0d1329 !important;
  border-radius: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 6px !important;
}

.react-datepicker__day-names {
  margin-top: 22px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #f6f4ff !important;
  color: #513cff !important;
}

.react-datepicker__day--disabled {
  color: #4da34e !important;
  background-color: rgba(77, 163, 78, 0.1) !important;
}

.react-datepicker__day {
  font-size: 12px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.react-datepicker__day-name {
  color: #929ca8 !important;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-size: 12px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #202529 !important;
  font-weight: bold;
  font-size: 13px !important;
}

.react-datepicker__month-container {
  // min-width: 350px;
  max-width: 420px;
  margin: 0 auto !important;
  margin-left: -8px !important;
}

// .react-datepicker__month-container {
//   margin: 0 !important;
// }

// .react-datepicker__month-container {
//   float: none !important;
//   width: min-content;
// }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  // margin: 10px 9px !important;
  height: 40px !important;
  width: 54px !important;
  max-height: 40px !important;
  max-width: 64px !important;
  // display: flex !important;
  // align-items: center !important;
  // justify-content: center !important;

  @media screen and (max-width: 640px) {
    width: 44px !important;
    max-width: 44px !important;
  }
}

.rdrMonth {
  min-width: 100% !important;
}

.react-datepicker__week {
  max-width: 400px;
  // background: blue;
  justify-content: space-between;
  gap: 40px !important;
}

.react-datepicker__day--selected {
  background: #e3f1fe !important;
  color: #0d1329 !important;
}

.react-datepicker__navigation {
  // border: 1px solid #efefef !important;
  border-radius: 100%;
}

.react-datepicker__navigation-icon--previous::before {
  top: 18px !important;
  left: -12px !important;
}

.react-datepicker__navigation-icon--next::before {
  top: 18px !important;
  left: -4px !important;
}

.react-datepicker__navigation {
  min-width: 32px !important;
  height: 32px !important;
  background: #000;
  margin-top: -20px;
}

.react-datepicker__day-name {
  font-weight: 500 !important;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px !important;
  font-style: normal;
  line-height: 14px;
  // text-transform: uppercase !important;
  background-color: #f8f9fb !important;
  border-bottom: 1px solid #d9d9d9;
  color: #0d1329 !important;
  padding-top: 6px !important;
}

.react-datepicker__current-month {
  font-weight: 500 !important;
  color: #0d1329 !important;
}

.selected-date.react-datepicker__day--selected {
  background: #4caf50 !important;
  color: white !important;
}

.react-datepicker__day--selected {
  background: #4caf50 !important;
  color: white !important;
}

.react-datepicker__week .react-datepicker__week-number {
  margin: 0px !important;
  background: #000 !important;
}

.react-datepicker__day--range-end {
  background-color: #513cff !important;
  color: #fff !important;
}

.react-datepicker__day--range-start {
  background-color: #513cff !important;
  color: #fff !important;
}
